

import { Grid, Button, IconButton, } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import InputField from '../component/CustomElement/InputField'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@iconify/react';
import SelectWithLabel from '../component/CustomElement/SelectWithLabel';
import RoomMaintainkey from '../component/AllcateRoom/RoomMaintainkey';
import SelectedRoomkey from '../component/AllcateRoom/SelectedRoomkey';
import { getroomListAndStatus } from '../api/room.api';
import { ErrorToaster, SuccessToaster, WarnToaster } from '../helper/toastHeleper';
import { guestRoomBooking, guestRoomCheckoutRoomList } from '../api/guest.api';
import { setAddNewGuestModalOpen, setRoomBookingDto, setRoomDetailsOpen, setRoomListStatausToUpdate, setRoomListToUpdate } from '../store/Reducers/modal';
import ConfirmationModal from '../helper/alertModal/ConfirmationModal';
import { deleteAssignRoomByid, getRoomCheckinByBookId } from '../api/dashoard.api';
import { getDivisionList } from '../api/division.api';
import { getCategoryList } from '../api/category.api';
// import { error } from 'autoprefixer/lib/utils';

// Utility function for deep cloning objects
const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

const CheckInFrom = ({ setNewRoomModalOpen = () => { } }) => {
  const roomBookingDto = useSelector(({ modal }) => modal.roomBookingDto, shallowEqual);
  const [selectedRoom, setSelectedRoom] = useState(deepClone(Array.isArray(roomBookingDto.selectedRoom) ? roomBookingDto.selectedRoom : []));
  const [searchRoom, setSearchRoomRoom] = useState("");
  const [roomselectionDetails, setRoomselectionDetails] = useState({ totalBed: 0, extrabed: 0 });
  const [selectedStatus, setSelectedStatus] = useState("Available")
  const [roomsByStatus, setRoomsByStatus] = useState({
    Available: [],
    Occupied: []
  });

  const dispatch = useDispatch();
  const options = ["Ahemdabad", "Surat", "Mumbai", "Patna"];

  const handleChange = (e, index) => {
    const { name, value, checked, type } = e.target;
    setSelectedRoom(prevFormData => {
      const newFormData = deepClone(prevFormData); // Deep clone previous form data
      if (!newFormData[index]) {
        newFormData[index] = {};
      }
      newFormData[index][name] = type === 'checkbox' ? checked : value;
      return newFormData;
    });
  };





  // mangaing room selection

  const handleSelectRoom = useCallback((roomValue) => {
    if (roomValue.status === 2) {
      WarnToaster({ massage: "Occupied Room cannot be selected" });
      return;
    }

    const newRoomsByStatus = { ...roomsByStatus };
    const index = newRoomsByStatus[selectedStatus].findIndex(({id})=>id===roomValue.id);
    const [selectedRoom] = newRoomsByStatus[selectedStatus].splice(index, 1);

    setSelectedRoom(prevSelectedRooms => [
      ...prevSelectedRooms,
      {
        ...selectedRoom,
        id: "",
        room_id: selectedRoom.id,
        original_key: 0,
        duplicate_key: 0,
        extra_key: 0,
        division_name:selectedRoom.division_name,
        isoriginal_key: selectedRoom.original_key,
        isduplicate_key: selectedRoom.duplicate_key,
        isextra_key: selectedRoom.extra_key,
        room_no: selectedRoom.room_no,
      }
    ]);

    setRoomsByStatus(newRoomsByStatus);
  }, [roomsByStatus, selectedStatus]);

  const handleRoomUnselect = useCallback(async (roomValue, index) => {
    const { assign_room_id, room_id } = roomValue;

    if (assign_room_id) {
      try {
        let res = await deleteAssignRoomByid({ assign_room_id });
        if (res.status === 200) {
          SuccessToaster({ massage: "Room Released Successfully" });
          const updatedSelectedRoom = selectedRoom.filter((roomSelected) => roomSelected.room_id !== room_id);
          setSelectedRoom(updatedSelectedRoom);
          fetchRoomListandStatus()
          return
        } else throw res;
      } catch (error) {
        ErrorToaster(error);
        return;
      }
    }
    const updatedSelectedRoom = selectedRoom.filter((roomSelected) => roomSelected.room_id !== room_id);

    if (!assign_room_id) {
      const newRoomsByStatus = { ...roomsByStatus };
      newRoomsByStatus.Available.push({
        ...roomValue,
        original_key: roomValue.isoriginal_key,
        duplicate_key: roomValue.isduplicate_key,
        extra_key: roomValue.isextra_key,
        id:room_id,
      });
      setRoomsByStatus(newRoomsByStatus);
    }

    setSelectedRoom(updatedSelectedRoom);
  }, [selectedRoom, roomsByStatus]);



  const statuses = useMemo(() => ({ 1: "Available", 2: "Occupied" }), [])

  const fetchRoomListandStatus = useCallback(async () => {
    try {
      let res = await getroomListAndStatus();
      if (res.status === 200) {
        const data = res.data;
        // Process the data to categorize by status
        data.sort((a, b) => parseInt(a.room_no) - parseInt(b.room_no));
        const categorizedRooms = data.reduce((acc, room) => {
          const statusLabel = statuses[room.status];
          if (statusLabel) {
            if (!acc[statusLabel]) {
              acc[statusLabel] = [];
            }
            acc[statusLabel].push(room);
          }
          return acc;
        }, {});
        setRoomsByStatus(categorizedRooms);
      } else {
        throw res;
      }
    } catch (error) {
      ErrorToaster(error);
    }
  }, [statuses])

  useEffect(() => {
    let countBed = 0
    let countExtrabed = 0
    selectedRoom.map((data) => {
      countBed = parseInt(countBed) + parseInt(data.bed)
      return countExtrabed = parseInt(countExtrabed) + parseInt(data.extra_bed)
    });
    setRoomselectionDetails(prev => ({
      ...prev,
      extrabed: countExtrabed,
      totalBed: countBed

    }));
  }, [selectedRoom]);

  // confirmation modal
  const [open, setOpen] = useState({})
  const [allocateOpen, setAllocateOpen] = useState({})

  const optionss = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  const handleClickOpen = () => {
    let { name="", surname="", office_purpose: { gents = 0, ladies = 0, child = 0 } = {} } = roomBookingDto
    const date = new Date();
    const dateString = date.toLocaleString('en-US', optionss);
    let totalGuest = (parseInt(gents || 0) + parseInt(ladies || 0) + parseInt(child || 0) + 1)
    setOpen({
      isopen: true,
      message:
        <div className=' text-center inter font-bold text-Text-Secondary'>
          <table className='text-left '>
            <tr >
              <td className='w-44 '>Guest Name:</td>
              <td className=''>{(name || "") + " " + (surname || "") + ""}</td>
            </tr>
            <tr>
              <td>Total Guest:</td>
              <td>{isNaN(totalGuest) ? 1 : totalGuest}</td>
            </tr>
            <tr>
              <td> Check-in time:</td>
              <td>{dateString}</td>
            </tr>
            <tr>
              <td className='float-start'>Room No.</td>
              <td>{selectedRoom.map((room, index) => <> <span className='text-nowrap'>B Block {room.room_no}</span>,{(index + 1) % 2 === 0 && <br />}</>)}</td>
            </tr>
            <tr>
              <td className='float-start'>Total Bed:</td>
              <td>{roomselectionDetails.totalBed}</td>
            </tr>
            <tr>
              <td className='float-start'>Extra Bed:</td>
              <td>{roomselectionDetails.extrabed}</td>
            </tr>
          </table>
        </div>
      ,
      id: ""
    });
  };
  const handleAllocateClickOpen = () => {
    let { name="", surname="", office_purpose: { gents = 0, ladies = 0, child = 0 } = {} } = roomBookingDto
    let totalGuest = (parseInt(gents || 0) + parseInt(ladies || 0) + parseInt(child || 0) + 1)
    setAllocateOpen({
      isopen: true,
      message:
        <div className=' text-center inter font-bold text-Text-Secondary'>
          <table className='text-left '>
            <tr >
              <td className='w-44 '>Guest Name:</td>
              <td className=''>{(name || "") + " " + (surname || "") + ""}</td>
            </tr>
            <tr>
              <td>Total Guest:</td>
              <td>{isNaN(totalGuest) ? 1 : totalGuest}</td>
            </tr>
            <tr>
              <td className='float-start'>Room No.</td>
              <td>{selectedRoom.map((room, index) => <> <span className='text-nowrap'>B Block {room.room_no}</span>,{(index + 1) % 2 === 0 && <br />}</>)}</td>
            </tr>
            <tr>
              <td className='float-start'>Total Bed:</td>
              <td>{roomselectionDetails.totalBed}</td>
            </tr>
            <tr>
              <td className='float-start'>Extra Bed:</td>
              <td>{roomselectionDetails.extrabed}</td>
            </tr>
          </table>
        </div>,
      id: ""
    });
  };


  const fetchRoomListByBookId = useCallback(async (id) => {
    try {
      let res = await guestRoomCheckoutRoomList(id);
      if (res.status === 200) {
        const data = res.data;
        setSelectedRoom(data);

      } else throw res;

    } catch (error) {
      ErrorToaster(error);
    }
  }, [])

  useEffect(() => {
    if (roomBookingDto.guest_room_book_id) {
      fetchRoomListByBookId({ guest_room_book_id: roomBookingDto.guest_room_book_id })
    }
    fetchRoomListandStatus();
  }, [])

  const handleMainClose = () => {
    dispatch(setNewRoomModalOpen(false))
    dispatch(setRoomDetailsOpen(false))
    dispatch(setRoomBookingDto({}))
    setSelectedRoom([])
  }

  const handleAllocateSubmmit = async () => {
    let { office_purpose = {} } = roomBookingDto
    let error = []
    const rooms = selectedRoom.map(data => {
      const isOriginalKeyValid = data.isoriginal_key ? Boolean(data.isoriginal_key && data.original_key) : false;
      const isDuplicateKeyValid = data.isduplicate_key ? Boolean(data.isduplicate_key && data.duplicate_key) : false;
      const isExtraKeyValid = data.isextra_key ? Boolean(data.isextra_key && data.extra_key) : false;
      if ((data.isoriginal_key || data.isduplicate_key || data.isextra_key) && !(isOriginalKeyValid || isDuplicateKeyValid || isExtraKeyValid)) {
        error.push({ massage: `Please Select One key From Room No. ${data.room_no}` })
      }
      return {
        id: data.id,
        room_id: data.room_id,
        original_key: data.original_key,
        duplicate_key: data.duplicate_key,
        extra_key: data.extra_key,
        division_name: data.division_name,
        assign_room_id: data.assign_room_id
      }
    });
    if (error.length > 0) {
      error.map((massage) => WarnToaster(massage))
      return
    }
    let formTosubmit = {
      guest_id: roomBookingDto.id,
      rooms,
      guest_office_purpose_id: office_purpose?.id,
      guest_room_book_id: roomBookingDto.guest_room_book_id,
      check_in_date: roomBookingDto.preCheck_in_date
    }
    try {
      let res = await guestRoomBooking(formTosubmit);
      if (res.status === 200) {
        handleMainClose()
        dispatch(setAddNewGuestModalOpen(false))
        dispatch(setRoomListStatausToUpdate(true))
        dispatch(setRoomListToUpdate(true))
        SuccessToaster(res)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  };

  const handlleCheckin = async () => {
    let { office_purpose = {} } = roomBookingDto
    let error = []
    const rooms = selectedRoom.map(data => {
      const isOriginalKeyValid = data.isoriginal_key ? Boolean(data.isoriginal_key && data.original_key) : false;
      const isDuplicateKeyValid = data.isduplicate_key ? Boolean(data.isduplicate_key && data.duplicate_key) : false;
      const isExtraKeyValid = data.isextra_key ? Boolean(data.isextra_key && data.extra_key) : false;
      if ((data.isoriginal_key || data.isduplicate_key || data.isextra_key) && !(isOriginalKeyValid || isDuplicateKeyValid || isExtraKeyValid)) {
        error.push({ massage: `Please Select One key From Room No. ${data.room_no}` })
      }
      return {
        id: data.id,
        room_id: data.room_id,
        original_key: data.original_key,
        duplicate_key: data.duplicate_key,
        extra_key: data.extra_key,
        division_name: data.division_name,
        assign_room_id: data.assign_room_id
      }
    });
    if (error.length > 0) {
      error.map((massage) => WarnToaster(massage))
      return
    }
    const date = new Date();
    const dateString = date.toLocaleString('en-US', optionss);
    let formTosubmit = {
      guest_id: roomBookingDto.id,
      rooms,
      guest_office_purpose_id: office_purpose?.id,
      check_in_date: dateString,
    }
    try {
      let res
      if (roomBookingDto.guest_room_book_id) {
        let AllocateNewRoom = rooms.filter(data => !data.assign_room_id);
        res = await getRoomCheckinByBookId({ guest_room_book_id: roomBookingDto.guest_room_book_id, rooms: AllocateNewRoom, guest_id: roomBookingDto.id })
      } else {
        res = await guestRoomBooking(formTosubmit);
      }

      if (res.status === 200) {

        handleMainClose()
        dispatch(setAddNewGuestModalOpen(false))
        dispatch(setRoomListStatausToUpdate(true))
        dispatch(setRoomListToUpdate(true))
        SuccessToaster(res)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  };
  let [filterBody, setFilterBody] = useState({
    category: {
      id: "",
      label: "All Category",
      value: "",
    },
    division: {
      id: "",
      label: "All Divison",
      value: "",
    }
  })
  let roomSearched = useMemo(() => {
    let Available = roomsByStatus["Available"]?.filter((room) => {
      const divisionMatch = filterBody.division?.id ? room.division_id === filterBody.division.id : true;
      const categoryMatch = filterBody.category?.id ? room.category_id === filterBody.category.id : true;
      const roomSearch = searchRoom ? String(room.room_no).includes(searchRoom) : true;
      return divisionMatch && categoryMatch && roomSearch;

    })
    let Occupied = roomsByStatus["Occupied"]?.filter((room) => {
      const divisionMatch = filterBody.division?.id ? room.division_id === filterBody.division.id : true;
      const categoryMatch = filterBody.category?.id ? room.category_id === filterBody.category.id : true;
      const roomSearch = searchRoom ? String(room.room_no).includes(searchRoom) : true;
      return divisionMatch && categoryMatch && roomSearch;

    })
    return { Occupied, Available }
  }, [selectedStatus, searchRoom, roomsByStatus, filterBody])

  const handleBack = () => {
    dispatch(setRoomBookingDto({ ...roomBookingDto, selectedRoom }));
    dispatch(setNewRoomModalOpen(false))
  }


  const [categoryList, setCategoryList] = useState([])
  const [divisionList, setDivisionList] = useState([])

  const handleFilter = (e) => {
    let { name, value } = e.target;

    setFilterBody((prev) => ({ ...prev, [name]: value }))

  };


  const getDivisions = useCallback(async () => {
    try {
      const res = await getDivisionList();
      if (res.status === 200) {
        let ress = []
        for (const division of res.data) {
          division.name &&
            ress.push({
              id: division.id,
              value: division.name,
              label: division.name,
            })
        }
        ress.unshift({
          id: "",
          label: "All Divison",
          value: "",
        })
        setDivisionList(ress);
      } else throw res;

    } catch (error) {
      ErrorToaster(error);
    }
  }, []);

  const getCategory = useCallback(async () => {
    try {
      const res = await getCategoryList();
      if (res.status === 200) {
        const ress = res.data.map(cat => ({
          id: cat.id,
          label: cat.name,
          value: cat.name,
        }));
        ress.unshift({
          id: "",
          label: "All Category",
          value: "",
        })
        setCategoryList(ress);
      } else throw res;

    } catch (error) {
      ErrorToaster(error);
    }
  }, []);

  useEffect(() => {
    getCategory();
    getDivisions();
  }, [getCategory, getDivisions]);
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={true}
        onClose={() => dispatch(setNewRoomModalOpen(false))}
      >
        <DialogTitle className=' border-border  flex justify-between items-center !border-1 !p-1 !px-5 inter font-bold '  >Select Room
          <IconButton className='!text-Primary-Color inline float-end my-auto ' onClick={handleMainClose}>
            <Icon icon="solar:close-square-line-duotone" className='text-Text-Secondary' ></Icon>
          </IconButton>
        </DialogTitle>
        <div className=' mx-5 my-5'>
          <Grid container sx={{ gap: 2 }} columns={6} >
            <Grid item md={1.3} lg={1} className=''>
              <InputField
                name='roomNo.'
                type="number"
                placeholder="Search By Room No."
                onChange={(e) => setSearchRoomRoom(e.target.value)}
                formData={searchRoom}
              />
            </Grid>
            <Grid item md={1.3} lg={1}>
              <SelectWithLabel
                options={categoryList}
                value={filterBody.category}
                onChange={handleFilter}
                name='category'
                defaltlable='Category'
                className=' w-46 bg-Primary-Button-BG h-10 rounded-md'
              />
            </Grid>
            <Grid item md={1.3} lg={1}>
              <SelectWithLabel
                options={divisionList}
                value={filterBody.division}
                onChange={handleFilter}
                name='division'
                defaltlable='Division'
                className='w-44 bg-Primary-Button-BG h-10 rounded-md'

              />
            </Grid>

            {/* <Grid item md={1.3} lg={2}>
              <SelectWithLabel
                options={options.map((label) => ({ label: label, value: label }))}
                value={selectedRoom.floor}
                // onChange={handleChange}
                name='floor'
                className='bg-Primary-Button-BG h-10 rounded-md w-40'
                defaltlable='Floor'
              />
            </Grid> */}

          </Grid>
        </div>
        <div className='flex gap-2 mb-2 p-3 '>
          {Object.keys(roomsByStatus).map((key) => <Button variant="contained" key={key} onClick={() => setSelectedStatus(key)} className={`${key} inter`} >{key} ({roomSearched?.[key]?.length})</Button>)}
        </div>
        <div className='bg-gray !py-3 border-1 border-border !px-5 inter font-bold mt-3 !text-sm uppercase'>
          Selected Room
        </div>
        <div className='mx-5 my-5'>

          {selectedRoom.length === 0 && <div className='text-center font-sans text-lg font-semibold'>No Rooms Selected</div>}
          <Grid container spacing={{ xs: 1, md: 1 }} columns={12}>
            {
              selectedRoom.length > 0 && selectedRoom.map((room, index) => <SelectedRoomkey key={room.room_id} handlecancel={handleRoomUnselect} handleChange={handleChange} index={index} roomDettails={room} />)
            }
          </Grid>
        </div>
        <div className='bg-gray !py-3 border-1 border-border !px-5 inter font-bold !text-sm uppercase'>
          Select Room
        </div>
        <div className='mx-5 my-5'>
          {!roomSearched?.[selectedStatus]?.length && <div className='text-center  font-sans text-lg font-semibold'>No Rooms Available</div>}
          <Grid container spacing={{ xs: 1, md: 1 }} columns={12}>
            {
              (Array.isArray(roomSearched?.[selectedStatus]) && roomSearched[selectedStatus].length > 0) && roomSearched[selectedStatus].map((room, index) => <RoomMaintainkey key={room.id} handleSelectRoomClick={handleSelectRoom} index={index} roomDettails={room} />)
            }
          </Grid>
        </div>

        <DialogActions sx={{ padding: 0 }}>
          <div className='bg-gray py-3  !px-5 inter flex w-full gap-3 h-full   justify-between '  >
            <div className='flex gap-3'>
              <span className='flex items-center inter font-semibold gap-2'>
                <Icon icon="solar:exit-line-duotone" />
                {selectedRoom.length}  Room Selected
              </span>
              <span className='flex items-center gap-2'>
                <Icon icon="solar:users-group-two-rounded-line-duotone" />
                {roomselectionDetails.totalBed} + {roomselectionDetails.extrabed} Guest
              </span>
            </div>
            <div>
              {<Button
                type="button"
                variant="outlined"
                onClick={handleBack}
                className=' !text-sm  2xl:text-base inter h-8 2xl:h-10 !rounded-lg'
              >
                <Icon icon="solar:arrow-left-line-duotone" className='text-xl mr-2' />
                Guest Details
              </Button>}
              {((!Boolean(roomBookingDto.guest_room_book_id) || roomBookingDto.isRoomEdit) || !Boolean(roomBookingDto.guest_room_book_id)) && <Button
                type="button"
                variant="contained"
                disabled={!selectedRoom.length || roomBookingDto.id === ""}
                disableElevation={true}
                onClick={() => handleAllocateClickOpen()}
                className='  !text-sm 2xl:text-base inter !ml-4 h-8 2xl:h-10 !rounded-lg hover:!bg-hover-Button-BG disabled:!bg-disabled-Button-BG !bg-Primary-Color'
              >
                {!roomBookingDto.isRoomEdit ? "Allocate Room" : "Update Room"}
                <Icon icon="solar:home-add-line-duotone" className='text-xl ml-2' />
              </Button>}
              {(!roomBookingDto.isRoomEdit || !Boolean(roomBookingDto.guest_room_book_id)) && <Button
                type="button"
                variant="contained"
                disabled={!selectedRoom.length || roomBookingDto.id === ""}
                onClick={() => handleClickOpen()}
                disableElevation={true}
                className='  !text-sm 2xl:text-base inter !ml-4 h-8 2xl:h-10 !rounded-lg hover:!bg-hover-Button-BG disabled:!bg-disabled-Button-BG !bg-Primary-Color'
              >
                Check-In
                <Icon icon="solar:import-line-duotone" className='text-xl ml-2 rotate-90' />
              </Button>}
            </div>
          </div>
          {/* <Button onClick={handleClose}>Close</Button> */}
        </DialogActions>
      </Dialog>


      <ConfirmationModal handleDelete={handlleCheckin} type='keyIssue' submitText='Check In' setOpen={setOpen} open={open} />
      <ConfirmationModal handleDelete={handleAllocateSubmmit} type='keyIssue' submitText='Room Allocate' setOpen={setAllocateOpen} open={allocateOpen} />
    </React.Fragment>


  );
};

export default CheckInFrom





