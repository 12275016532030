import { Button, FormControlLabel, Grid, IconButton } from '@mui/material'
import React from 'react'
import CusomCheckbox from '../CustomElement/CustomCheckbox'
import { Icon } from '@iconify/react/dist/iconify.js'

const SelectedRoomkey = ({ roomDettails, index, handleChange = () => { }, handlecancel,isDisabled=false }) => {
    return (
        <Grid item md={3} lg={2.4} >
            <div className='flex items-center gap-2'>
                <Button variant="contained" type='button' disableElevation={!handlecancel} sx={{border:"1px solid !important"}} className={`w-[120px] h-[120px]  !rounded-lg ${handlecancel?roomDettails.status_label:"!bg-gray !text-Text-Secondary !border-Text-Secondary"} inter  flex flex-col justify-around items-center relative`}>
                    {handlecancel  && <IconButton className='!absolute -top-1 -right-1' onClick={() => handlecancel(roomDettails, index)} >
                        <Icon className='text-sm' icon="solar:close-square-outline" />
                    </IconButton>}
                    <div className=''>
                        <p className='text-[10px] max-h-8 overflow-hidden min-h-3 inter !font-bold text-center select-none'>{roomDettails.division_name || ""}</p>
                        <p className='text-sm inter !font-bold text-center select-none'>{roomDettails.room_no}</p>
                        <p className='text-xs inter !font-bold text-center select-none'>{roomDettails.bed || 0} + {roomDettails.extra_bed || 0}</p>
                    </div>
                </Button>
                <div>
                    <span className='flex items-center'>
                        <FormControlLabel
                            id='original_key'
                            name='original_key'
                            disabled={isDisabled || !Boolean(roomDettails.isoriginal_key)}
                            checked={Boolean(roomDettails.original_key)}
                            onChange={(e) => handleChange(e, index)}
                            className='!m-0'
                            control={<CusomCheckbox className='!p-0  !mr-2' />} />
                        <label className='text-sm font-normal text-Text-Secondary mb-1  text-nowrap ' htmlFor='sameWhattsNumber'>Original Key</label>
                    </span>
                    <span className='flex items-center'>
                        <FormControlLabel
                            id='duplicate_key'
                            name='duplicate_key'
                            disabled={isDisabled || !Boolean(roomDettails.isduplicate_key)}
                            checked={Boolean(roomDettails.duplicate_key)}
                            onChange={(e) => handleChange(e, index)}
                            className='!m-0'
                            control={<CusomCheckbox className='!p-0  !mr-2' />} />
                        <label className='text-sm font-normal text-Text-Secondary mb-1 text-nowrap ' htmlFor='sameWhattsNumber'>Duplicate Key</label>
                    </span>
                    <span className='flex items-center'>
                        <FormControlLabel
                            id='extra_key'
                            name='extra_key'
                            disabled={isDisabled || !Boolean(roomDettails.isextra_key)}
                            checked={Boolean(roomDettails.extra_key)}
                            onChange={(e) => handleChange(e, index)}
                            className='!m-0'
                            control={<CusomCheckbox className='!p-0  !mr-2' />} />
                        <label className='text-sm font-normal text-Text-Secondary mb-1  text-nowrap ' htmlFor='sameWhattsNumber'>Extra Key</label>
                    </span>

                </div>
            </div>
        </Grid>
    )
}

export default SelectedRoomkey